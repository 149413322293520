<template>
  <div>
    <el-upload
      action="#"
      ref="uploadRef"
      class="uploader"
      :show-file-list="false"
      :disabled="disabled || loading"
      :http-request="handleHttpRequest"
    >
      <slot>
        <el-button :disabled="disabled" type="primary" :loading="loading">
          <slot name="text">{{ title }}</slot>
        </el-button>
      </slot>
    </el-upload>

    <div v-if="fileUrl != ''">
      <a
        v-if="fileSuffix == 'pdf'"
        :href="fileUrl"
        style="color: #0091ff"
        target="_blank"
        rel="noopener noreferrer"
        >{{ fileName }}</a
      >
      <a
        class="img_outter"
        v-else
        :href="fileUrl"
        style="color: #0091ff"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img :src="fileUrl" class="img" />
      </a>
    </div>
  </div>
</template>

<script>
import request from "@/config/serve.js";
export default {
  model: { prop: "url", event: "change" },
  props: {
    title: { default: "上传文件" },
    url: { default: "" },
    type: { default: "other" }, // 上传的文件类型，可用值:apk,image,other,video
    expandData: {}, // 其他附加数据
    // 接受上传的文件类型
    acceptType: {
      type: Array,
      default() {
        return [];
      },
    },
    // 限制大小单位为MB
    limSize: {
      type: Number,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileUrl: this.url,
      loading: false,
    };
  },
  computed: {
    fileName() {
      let arr = this.fileUrl.split("/");
      const fName = arr[arr.length - 1];
      return fName;
    },
    fileSuffix() {
      let arr = this.fileUrl.split("/");
      const fName = arr[arr.length - 1];
      let arrName = fName.split(".");
      return arrName[arrName.length - 1];
    },
  },
  methods: {
    async handleHttpRequest({ file }) {
      const isUpload = await this.beforeUpload(file);
      if (isUpload) {
        this.loading = true;
        // 文件上传至服务器
        const res = await this.uploadFile(file, this.type);
        if (res && res.code === 0) {
          if (this.type == "apk") {
            const { fileProperty = {} } = res.data;
            this.fileUrl = fileProperty.accessPath || "";
          } else {
            this.fileUrl = res.data.accessPath || "";
          }
          this.$emit("success", res.data);
        }
        this.loading = false;
      }
    },
    async beforeUpload(file) {
      const acceptType = this.acceptType; // 限制上传类型
      const limSize = this.limSize; // 限制大小单位为MB
      let [isFileType, isFileSize] = [true, true];

      if (acceptType.length != 0) {
        // 父组件传入可接受类型
        isFileType = acceptType.includes(file.type.split("/")[1]);
      }

      if (limSize) {
        // 父组件传入限制大小
        isFileSize = file.size / 1024 / 1024 <= limSize;
      }
      if (!isFileType) {
        this.$message.error(`上传文件只能是 ${acceptType.join("、")} 格式！`);
        return isFileType;
      }
      if (!isFileSize) {
        this.$message.error(`上传文件大小不能超过 ${limSize}MB!`);
        return isFileSize;
      }
      return isFileType && isFileSize;
    },
    uploadFile(file, type = "other") {
      const formData = new FormData();
      formData.set("file", file);
      let url = "/file/otherUpload"; // 上传其他文档
      if (type === "apk") {
        // 上传apk文档
        url = "/file/apkUpload";
        const { appInfoId = "", verify = true } = this.expandData;
        formData.set("appInfoId", appInfoId);
        formData.set("verify", verify);
      } else if (type === "image") {
        // 上传图片
        url = "/file/imageUpload";
      } else if (type === "video") {
        // 上传视频
        url = "/file/videoUpload";
      }
      return request(
        "http",
        url,
        {
          method: "post",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        },
        false
      );
    },
    // 暴露给父组件校验组件文件是否正在上传
    getValidate(showMsg = true) {
      if (this.loading) {
        if (showMsg) {
          this.$message.error("文件正在上传，请稍后；");
        }
        return false;
      }
      return true;
    },
  },
  watch: {
    url(url) {
      if (url === this.fileUrl) return;
      this.fileUrl = url;
    },
    fileUrl(url) {
      this.$emit("change", url);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img_outter {
  width: 120px;
  height: 100px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 4px;

  .img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
